
.readOnly {
  border: none;
  width: 100%; 
}

.mentions {
  margin: 1em 0 2em 0;
  cursor: default;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 6px;
}
.mentions:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

}


.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  background-color: #cee4e5;
  
}