* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.register_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper_container {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    width: 553px;
}

.register_container h2 {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;
}

.register_container label {
    font-size: 14px;
}
.register_container a{
    /* text-decoration: underline; */
    color: #1e90ff;
    line-height: 12px;
}

.register_container input {
    width: 350px;
    height: 35px;
    border-radius: 3px;
    padding: 10px;
    outline: none;
    font-size: 15px;
    margin-top: 5px;
    border: 1px solid #1e90ff;
}

.register_button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px 15px;
    border-radius: 5px;
    outline: none;
    font-size: 15px;
    background-color: #FC9334;
    border: none;
    width: 80%;
    color: #ffffff;
    cursor: pointer;
}
.register_button.google{
    background-color: white;
    color: black;
    border: 1px solid #1e90ff;
}

.account_wrapper {
    text-align: center;
    margin-top: 8px;
}
.account {
    font-size: 13px;
}
.line-middle:before, 
.line-middle:after { 
    content: ""; 
    flex: 1 1; 
    border-bottom: 1px solid #000; 
    margin: auto; 
} 

@keyframes slideRight {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(0%);
    }
  }
  
  .animate-slideRight {
    animation: slideRight 0.3s ease-out;
  }